import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import closeIcon from '../../assets/images/close-icon.png';
import stepper1 from '../../assets/images/stepper1.png';
import stepper2 from '../../assets/images/stepper2.png';
import stepper3 from '../../assets/images/stepper3.png';
import { createPartner, createPartnerUser } from '../../services/PartnersService';
import { getAllTradeMark } from '../../services/DataService';
import Loader from '../../components/Shared/Loader';
import './index.scss';
import Select from 'react-select';

const AddPartner = (props: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [businessModelList, setBusinessModelList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [integrationTypeList, setIntegrationTypeList] = useState<any[]>([]);
  const [trademarksList, setTrademarksList] = useState<any[]>([]);
  const [selectedTrademark, setSelectedTrademark] = useState('');

  const validEmailRegex = /^\S+@(?!hm\.com$|hennesandmauritz\.onmicrosoft\.com$)\S+\.\S+$/;

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = () => {
    setShowLoader(true);
    getAllTradeMark()
      .then(function (data) {
        let trademarks: any = [];
        for (let trademark of data) {
          if (trademark.brandName) {
            let trademarkData: any = { value: trademark.brandName, label: trademark.brandName };
            trademarks.push(trademarkData);
          }
        }
        setTrademarksList(trademarks?.sort((a, b) => a?.value?.trim().localeCompare(b?.value?.trim(), 'sv')));
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });

    setBusinessModelList(props.getAllFiltersData?.businessModels);
    setCategoryList(props.getAllFiltersData?.categories);
    setBrandsList(props.getAllFiltersData?.brands?.sort((a, b) => a?.brandName?.localeCompare(b?.brandName, 'sv')));
    setIntegrationTypeList(props.getAllFiltersData?.integrationTypes);
  };

  const onSubmit = (data: any) => {
    if (
      watch('partnerName') &&
      watch('firstName') &&
      watch('lastName') &&
      watch('email').match(validEmailRegex) &&
      watch('brandId')
    ) {
      setShowLoader(true);
      if (data['integrationTypeId']) {
        data['integrationTypeId'] = data['integrationTypeId'];
      } else {
        data['integrationTypeId'] = '10005';
      }
      data['firstName'] = data.firstName?.trim();
      data['lastName'] = data.lastName?.trim();
      data['email'] = data.email?.trim();
      createPartner(data)
        .then(function (response) {
          let partnerId: string = response;
          const partnerUser: any = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          };
          createPartnerUserDetail(partnerUser, partnerId);
        })
        .catch(function (error) {
          setShowLoader(false);
          console.log(error);
        });
    }
  };

  const createPartnerUserDetail = (partnerUser: any, partnerId: string) => {
    createPartnerUser(partnerUser, partnerId)
      .then(function (data) {
        setShowLoader(false);
        props.addPartnerSuccess();
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  const stepNotCompleted = () => {
    setStepOne(true);
    setStepTwo(false);
    setStepThree(false);
  };

  const stepTwoNotCompleted = () => {
    setStepOne(false);
    setStepTwo(true);
    setStepThree(false);
  };

  const stepOneCompleted = () => {
    if (watch('partnerName')) {
      setStepOne(false);
      setStepTwo(true);
      setStepThree(false);
    } else {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }
  };

  const stepTwoCompleted = () => {
    if (watch('firstName') && watch('lastName') && watch('email').match(validEmailRegex)) {
      setStepOne(false);
      setStepTwo(false);
      setStepThree(true);
    } else {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }
  };

  const handleInputOnChange = (e) => {
    if (e.target.name === 'shopId') {
      setValue(e.target.name, e.target.value.replace(/[^\d,]+/g, ''));
    } else {
      setValue(e.target.name, e.target.value.replace(/[^\d]+/g, ''));
    }
  };

  const handleTrademark = (selectedOption: any) => {
    let selectedTrademarks: any = [];
    for (let trademark of selectedOption) {
      selectedTrademarks.push(trademark.value);
    }
    setSelectedTrademark(selectedOption);
    setValue('trademarkName', selectedTrademarks?.join(','));
  };

  return (
    <div className="popup-backdrop addPartner">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6>
            Add a new partner{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <img src={closeIcon} alt="x" />
            </span>
          </h6>
        </div>

        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className="addUser-popup--body">
            {stepOne ? <img src={stepper1} alt="Stepper" /> : ''}
            {stepTwo ? <img src={stepper2} alt="Stepper" /> : ''}
            {stepThree ? <img src={stepper3} alt="Stepper" /> : ''}
            <br />
            <br />

            {stepOne ? (
              <div>
                <input
                  {...register('partnerName', { required: true })}
                  className={`form-control ${errors.partnerName ? 'error-control' : ''}`}
                  placeholder="Partner name"
                  autoComplete="off"
                />
                {errors.partnerName && <span>Please enter partner name</span>}

                <label className="tool" data-tip="This field needs to be filled to enable the Asset tool.">
                  <i className="icon-Info"></i>
                </label>
                <Select
                  {...register('trademarkName', { required: false })}
                  className={`trademarkMultiDropdown ${errors.trademarkName ? 'error-control' : ''}`}
                  isMulti={true}
                  value={selectedTrademark}
                  onChange={handleTrademark}
                  options={trademarksList}
                  placeholder="Brand"
                />
                {errors.trademarkName && <span>Please choose brand</span>}

                <label
                  className="tool"
                  data-tip="This field needs to be filled to enable the insights feature. Only needed for dropship partners."
                >
                  <i className="icon-Info"></i>
                </label>
                <input
                  {...register('shopId', { required: false, onChange: (e) => handleInputOnChange(e) })}
                  className={`form-control ${errors.shopId ? 'error-control' : ''}`}
                  placeholder="Mirakl Shop Id"
                  autoComplete="off"
                />
                {errors.shopId && <span>Please enter your Mirakl Shop-id (Min and Max 4 digits)</span>}

                <label
                  className="tool"
                  data-tip="This field needs to be filled to enable the insights feature. Only needed for wholesale partners."
                >
                  <i className="icon-Info"></i>
                </label>
                <input
                  {...register('supplierId', { required: false, onChange: (e) => handleInputOnChange(e) })}
                  className={`form-control ${errors.supplierId ? 'error-control' : ''}`}
                  placeholder="Spur Id"
                  autoComplete="off"
                />
                {errors.supplierId && <span>Please enter your Spur-id</span>}
              </div>
            ) : (
              ''
            )}

            {stepTwo ? (
              <div>
                <input
                  {...register('firstName', { required: true })}
                  className={`form-control ${errors.firstName ? 'error-control' : ''}`}
                  placeholder="First name"
                  autoComplete="off"
                />
                {errors.firstName && <span>Please enter your firstname</span>}

                <input
                  {...register('lastName', { required: true })}
                  className={`form-control ${errors.lastName ? 'error-control' : ''}`}
                  placeholder="Last name"
                  autoComplete="off"
                />
                {errors.lastName && <span>Please enter your lastname</span>}

                <input
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^\S+@(?!hm\.com$|hennesandmauritz\.onmicrosoft\.com$)\S+\.\S+$/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  className={`form-control ${errors.email ? 'error-control' : ''}`}
                  placeholder="Email address"
                  autoComplete="off"
                />
                {errors.email && <span>Please enter your valid email</span>}
              </div>
            ) : (
              ''
            )}

            {stepThree ? (
              <div>
                <select
                  {...register('brandId', { required: true })}
                  className={`form-control ${errors.brandId ? 'error-control' : ''}`}
                >
                  <option value="">H&M Group Brand</option>
                  {brandsList &&
                    brandsList.map((item: any) => {
                      return <option value={item.brandId}>{item.brandName}</option>;
                    })}
                </select>
                {errors.brandId && <span>Please choose H&M brand</span>}

                <select
                  {...register('businessModelId', { required: true })}
                  className={`form-control ${errors.businessModelId ? 'error-control' : ''}`}
                >
                  <option value="">Business Model</option>
                  {businessModelList &&
                    businessModelList.map((item: any) => {
                      return <option value={item.businessModelId}>{item.businessModelName}</option>;
                    })}
                </select>
                {errors.businessModelId && <span>Please choose business model</span>}

                <select
                  {...register('categoryId', { required: true })}
                  className={`form-control ${errors.categoryId ? 'error-control' : ''}`}
                >
                  <option value="">Category</option>
                  {categoryList &&
                    categoryList.map((item: any) => {
                      return <option value={item.categoryId}>{item.categoryName}</option>;
                    })}
                </select>
                {errors.categoryId && <span>Please choose category</span>}

                <select
                  {...register('integrationTypeId', { required: false })}
                  className={`form-control ${errors.integrationTypeId ? 'error-control' : ''}`}
                >
                  <option value="">Integration type</option>
                  {integrationTypeList &&
                    integrationTypeList.map((item: any) => {
                      return <option value={item.integrationTypeId}>{item.integrationTypeName}</option>;
                    })}
                </select>
                {errors.integrationTypeId && <span>Please choose integration type</span>}
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="addUser-popup--footer">
            {stepOne ? (
              <Button
                className="Button"
                onClick={() => {
                  stepOneCompleted();
                }}
              >
                Next
              </Button>
            ) : (
              ''
            )}
            {stepTwo ? (
              <div>
                <Button
                  className="Button mb-1"
                  onClick={() => {
                    stepTwoCompleted();
                  }}
                >
                  Next
                </Button>
                <Button
                  className="Button Button--outlined Button--back"
                  onClick={() => {
                    stepNotCompleted();
                  }}
                >
                  Back
                </Button>
              </div>
            ) : (
              ''
            )}
            {stepThree ? (
              <div>
                <Button type="submit" className="Button mb-1">
                  Add partner
                </Button>
                <Button
                  className="Button Button--outlined Button--back"
                  onClick={() => {
                    stepTwoNotCompleted();
                  }}
                >
                  Back
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </form>
        {showLoader ? <Loader /> : ''}
      </div>
    </div>
  );
};

export default AddPartner;
