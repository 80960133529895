import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePartner } from '../../store/actions/partnerAction';
import { updatePartnerProfiles } from '../../store/actions/partnerProfilesAction';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import './index.scss';
import Button from '../../components/Shared/Button';
import AddPartner from '../../components/AddPartner';
import AddEntity from '../../components/AddEntity';
import DeletePartner from '../../components/DeletePartner';
import Notification from '../../components/Notification';
import Loader from '../../components/Shared/Loader';
import { getAllPartners } from '../../services/PartnersService';
import UpdatePartner from '../../components/UpdatePartner';
import DeleteEntity from '../../components/DeleteEntity';
import Select from 'react-select';
import { getAllFilters } from '../../services/DataService';

const PartnerProfile = () => {
  const [partnerProfilesList, setPartnerProfilesList] = useState<any[]>([]);
  const [showAddPartner, setAddPartner] = useState(false);
  const [showUpdatePartner, setUpdatePartner] = useState(false);
  const [showAddEntity, setAddEntity] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState('');
  const [showDeletePartner, setShowDeletePartner] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [partnerId, setPartnerId] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [partnerEdit, setPartnerEdit] = useState(false);
  const [showDeleteEntity, setDeleteEntity] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<any[]>([]);
  const [selectedBusinessModel, setSelectedBusinessModel] = useState('');
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [businessModelList, setBusinessModelList] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [getAllFiltersData, setGetAllFiltersData] = useState<any[]>([]);

  let history = useHistory();
  const dispatch = useDispatch();

  const addEntityData = {
    title: 'Add new entity',
    content: 'Entity has been added!',
    action: 'partnerProfiles',
  };
  const addPartnerData = {
    title: 'Add new partner',
    content: 'Partner has been added!',
    action: 'partnerProfiles',
  };
  const updatePartnerData = {
    title: 'Update partner',
    content: 'Partner has been updated!',
    action: 'partnerProfiles',
  };
  const deletePartnerData = {
    title: 'Delete partner',
    content: 'Partner has been deleted!',
    action: 'partnerProfiles',
  };

  const deleteEntityData = {
    title: 'Delete entity',
    content: 'Entity has been deleted!',
    action: 'partnerProfiles',
  };

  useEffect(() => {
    if (!showNotification) {
      getPartnersList();
    }
  }, [showNotification]);

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = () => {
    setShowLoader(true);
    getAllFilters()
      .then(function (data) {
        setGetAllFiltersData(data);
        let businessModels: any = [];
        for (let businessModel of data.businessModels) {
          let marketData: any = { value: businessModel.businessModelId, label: businessModel.businessModelName };
          businessModels.push(marketData);
        }
        setBusinessModelList(businessModels);
        let categories: any = [];
        for (let category of data.categories) {
          let marketData: any = { value: category.categoryId, label: category.categoryName };
          categories.push(marketData);
        }
        setCategoryList(categories);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getPartnersList = () => {
    closePopup();
    setShowLoader(true);
    getAllPartners()
      .then(function (data) {
        if (data) {
          dispatch(updatePartnerProfiles(data));
        }

        setPartnerProfilesList(data);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const closePopup = () => {
    setAddPartner(false);
    setUpdatePartner(false);
    setAddEntity(false);
    setDeleteEntity(false);
    setNotification(false);
    setShowDeletePartner(false);
  };

  const addPartnerSuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(addPartnerData);
  }, []);

  const updatePartnerSuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(updatePartnerData);
  }, []);

  const addEntitySuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(addEntityData);
  }, []);

  const showPartnerDetail = (partner: any) => {
    dispatch(updatePartner(partner));
    window.sessionStorage.setItem('partnerDetail', JSON.stringify(partner));
    window.sessionStorage.setItem('partnerId', partner.partnerId);
    history.push('/partner-user');
  };

  const addEntity = (partner: any) => {
    setSelectedPartner(partner);
    setAddEntity(true);
  };

  const deletePartnerDetail = (partner: any) => {
    setSelectedPartner(partner);
    setShowDeletePartner(true);
  };

  const deleteSuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(deletePartnerData);
  }, []);

  const deleteEntity = (partner: any, entity: any) => {
    setSelectedPartner(partner);
    setSelectedEntity(entity);
    setDeleteEntity(true);
  };

  const deleteEntitySuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(deleteEntityData);
  }, []);

  const sortPartners = (sort) => {
    setSelectedOption(sort);
  };

  const onInputchange = (event: any) => {
    setSearchValue(event.target.value);
    if (event.target.value.length < 3) {
      setSearchWord('');
    } else {
      if (event.target.value.length > 3) {
        setSearchWord(event.target.value);
      }
    }
  };

  const onInputClear = () => {
    setSearchValue('');
    setSearchWord('');
  };

  const partnerNameHandle = (partner: any) => {
    setPartnerId(partner);
    partnerId === partner || partnerId === 0 ? setPartnerEdit(!partnerEdit) : setPartnerEdit(true);
  };

  const updatePartnerHandle = (event: any, partner: any) => {
    event.stopPropagation();
    setSelectedPartner(partner);
    setPartnerId(partner.partnerId);
    setPartnerEdit(true);
    setUpdatePartner(!showUpdatePartner);
  };

  const handleBusinessModelChange = (selectedOption: any) => {
    setSelectedBusinessModel(selectedOption);
    let marketItems: any = [];
    if (selectedOption) {
      marketItems.push(selectedOption?.label);
    }
  };

  const handleCategoryChange = (selectedOption: any) => {
    setSelectedCategory(selectedOption);
    let marketItems: any = [];
    for (let val of selectedOption) {
      marketItems.push(val?.label);
    }
  };

  const finalPartnerProfileData = useMemo(() => {
    let computedPartnerProfileData = partnerProfilesList;
    const portfolioBrand = JSON.parse(sessionStorage.getItem('portfolioBrand')!);

    if (searchWord) {
      computedPartnerProfileData =
        computedPartnerProfileData &&
        computedPartnerProfileData?.filter((partners: any) =>
          partners.partnerName.toLowerCase().includes(searchWord.toLowerCase()),
        );
    }

    if (selectedBusinessModel && Object.keys(selectedBusinessModel)?.length > 0) {
      const selectedBusinessmodelValue = JSON.parse(JSON.stringify(selectedBusinessModel))?.value;
      computedPartnerProfileData =
        computedPartnerProfileData &&
        computedPartnerProfileData?.filter((partners: any) =>
          partners?.partnerEntity?.some((entity: any) => entity?.businessModelId === selectedBusinessmodelValue),
        );
    }

    if (selectedCategory && Object.keys(selectedCategory)?.length > 0) {
      computedPartnerProfileData =
        computedPartnerProfileData &&
        computedPartnerProfileData?.filter((partners: any) =>
          partners?.partnerEntity?.some((entity: any) =>
            selectedCategory?.some((category: any) => entity?.categoryId === category?.value),
          ),
        );
    }

    if (portfolioBrand && Object.keys(portfolioBrand)?.length > 0) {
      computedPartnerProfileData =
        computedPartnerProfileData &&
        computedPartnerProfileData?.filter((partners: any) =>
          partners?.partnerEntity?.some((entity: any) => entity?.brandId === portfolioBrand?.value),
        );
    }

    if (selectedOption) {
      if (selectedOption === 'ASCENDING') {
        computedPartnerProfileData =
          computedPartnerProfileData &&
          computedPartnerProfileData.sort((a, b) => a.partnerName.localeCompare(b.partnerName, 'sv'));
      } else {
        computedPartnerProfileData =
          computedPartnerProfileData &&
          computedPartnerProfileData.sort((a, b) => b.partnerName.localeCompare(a.partnerName, 'sv'));
      }
    }

    return computedPartnerProfileData;
  }, [
    partnerProfilesList,
    searchWord,
    selectedCategory,
    selectedBusinessModel,
    selectedOption,
    sessionStorage.getItem('portfolioBrand')!,
  ]);

  return (
    <div className="partner-profile">
      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="Profile-hero">
              <h2 className="mt-4 Heading">Partner Profiles</h2>

              <div className="filter-section">
                <a>
                  <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                      sort by <span className="icon-Arrows-Down"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <form>
                        <Dropdown.Item>
                          <label onClick={() => sortPartners('ASCENDING')}>
                            <input
                              type="radio"
                              checked={selectedOption === 'ASCENDING'}
                              onChange={(e) => sortPartners(e)}
                              name="sorting"
                              value="ASCENDING"
                            />{' '}
                            Sort A to Z
                          </label>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <label onClick={() => sortPartners('DESCENDING')}>
                            <input
                              type="radio"
                              checked={selectedOption === 'DESCENDING'}
                              onChange={(e) => sortPartners(e)}
                              name="sorting"
                              value="DESCENDING"
                            />{' '}
                            Sort Z to A
                          </label>
                        </Dropdown.Item>
                      </form>
                    </Dropdown.Menu>
                  </Dropdown>
                </a>
                {/* <a onClick={() => {setShowFilter(true)}}><i className="icon-Settings"></i> filter</a> */}
                {/* <a className='pull-right'>
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic1">
                                export <span className="icon-Upload"></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>Export as .xlsx</Dropdown.Item>
                                    <Dropdown.Item>Export as .doc</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown></a> */}
              </div>
            </div>
            <Row>
              <Col xs={4} sm={4} md={4}>
                <div className="right-inner-addon">
                  {searchValue && <i className="icon-Delete-circle" onClick={onInputClear}></i>}
                  <input
                    className={`form-control searchBox`}
                    value={searchValue}
                    placeholder="Search for Partner"
                    onChange={onInputchange}
                  />
                </div>
              </Col>
              <Col xs={3} sm={3} md={3}>
                <Select
                  value={selectedBusinessModel}
                  onChange={handleBusinessModelChange}
                  options={businessModelList}
                  placeholder="Business Model"
                  isClearable
                />
              </Col>
              <Col xs={3} sm={3} md={3}>
                <Select
                  isMulti={true}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  options={categoryList}
                  placeholder="Category"
                />
              </Col>
            </Row>
            <div className="list u-marginBxlg">
              {finalPartnerProfileData.length === 0 ? <p>No results found</p> : null}
              <Accordion>
                {finalPartnerProfileData &&
                  finalPartnerProfileData
                    ?.sort((a, b) => a?.partnerName?.trim().localeCompare(b?.partnerName?.trim(), 'sv'))
                    ?.map((partner, index) => {
                      const eventId = partner?.partnerId.toString() + index?.toString();
                      return (
                        <Accordion.Item eventKey={eventId} key={eventId}>
                          <span onClick={() => partnerNameHandle(partner.partnerId)}>
                            <Accordion.Header className="fontFamilySecondary">
                              {partner.partnerName}
                              {partnerEdit && partner.partnerId === partnerId ? (
                                <>
                                  <Button
                                    className="manage-button no-border"
                                    onClick={(event) => updatePartnerHandle(event, partner)}
                                  >
                                    <i className="icon-User"></i>
                                    <span>Manage Partner</span>
                                  </Button>{' '}
                                  <Button
                                    className="manage-button no-border"
                                    onClick={() => {
                                      showPartnerDetail(partner);
                                    }}
                                  >
                                    <i className="icon-User-multiple"></i>
                                    <span>Manage Users</span>
                                  </Button>
                                </>
                              ) : null}
                              <span className="entities">({partner.partnerEntity.length} entities)</span>
                            </Accordion.Header>
                          </span>
                          <Accordion.Body>
                            <div className="users-table">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Brand</th>
                                    <th>Business Unit</th>
                                    <th>Category</th>
                                    <th>Integration Type</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {partner.partnerEntity &&
                                    partner.partnerEntity.map((entity: any) => {
                                      return (
                                        <tr key={entity.brandId}>
                                          <td>{entity.brand}</td>
                                          <td>{entity.businessModel}</td>
                                          <td>{entity.category}</td>
                                          <td>{entity.integrationType}</td>
                                          <td className="u-textRight">
                                            <Button
                                              className="Button Button--round Button--small Button--red Button--title"
                                              data-title="Delete entity"
                                              onClick={() => {
                                                deleteEntity(partner, entity);
                                              }}
                                            >
                                              <i className="icon-Close"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>

                            <div className="add-entity">
                              <Button
                                className="Button Button--whiteBlack"
                                data-testid="addEntityTest"
                                onClick={() => {
                                  addEntity(partner);
                                }}
                              >
                                Add new entity
                              </Button>

                              <Button
                                className="UploadFooter-delete  Button Button--whiteBlack delete-partner"
                                onClick={() => {
                                  deletePartnerDetail(partner);
                                }}
                              >
                                <i className="icon-Delete-bin"></i>
                                <span>Delete {partner.partnerName}</span>
                              </Button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
              </Accordion>
            </div>

            <div className="add-users text-center">
              <Button
                className="Button Button--whiteBlack"
                onClick={() => {
                  setAddPartner(!showAddPartner);
                }}
              >
                Add new partner
              </Button>
            </div>

            {showAddPartner ? (
              <AddPartner
                closePopup={closePopup}
                addPartnerSuccess={addPartnerSuccess}
                getAllFiltersData={getAllFiltersData}
              />
            ) : (
              ''
            )}
            {showUpdatePartner ? (
              <UpdatePartner
                selectedPartner={selectedPartner}
                closePopup={closePopup}
                updatePartnerSuccess={updatePartnerSuccess}
              />
            ) : (
              ''
            )}
            {showAddEntity ? (
              <AddEntity
                selectedPartner={selectedPartner}
                closePopup={closePopup}
                addEntitySuccess={addEntitySuccess}
                getAllFiltersData={getAllFiltersData}
              />
            ) : (
              ''
            )}

            {showDeleteEntity ? (
              <DeleteEntity
                entity={selectedEntity}
                partnerName={selectedPartner?.partnerName}
                closePopup={closePopup}
                deleteSuccess={deleteEntitySuccess}
              />
            ) : (
              ''
            )}
            {showDeletePartner ? (
              <DeletePartner selectedPartner={selectedPartner} closePopup={closePopup} deleteSuccess={deleteSuccess} />
            ) : (
              ''
            )}
            {showNotification ? (
              <Notification data={notificationData} closePopup={closePopup} getPartnersList={getPartnersList} />
            ) : (
              ''
            )}
            {showLoader ? <Loader /> : ''}
            {showSuccess ? (
              <div className="alert alert-success show-failed">
                <strong>Success!</strong> Partner name updated.
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PartnerProfile;
