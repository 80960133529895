import { useState, useEffect } from 'react';
import './index.scss';
import ArticleHero from '../../components/ArticleHero';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Tabs from '../../components/Shared/Tabs';
import Block from '../../components/Block';
import Toc from '../../components/Shared/Toc';
import Nextarticle from '../../components/Shared/Nextarticle';
import { getContent, getNotification, getArticles } from '../../services/CmsService';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/Shared/Loader';
import Notification from '../../assets/images/icons/Notification.svg';
import NotificationArticles from '../../components/NotificationArticles';
import { useDispatch } from 'react-redux';
import { updateAllArticles } from '../../store/actions/learnAction';

const Article = (props: any) => {
  const dispatch = useDispatch();
  let search = window.location.search;
  let par = new URLSearchParams(search);
  let isOnboarding = par.get('from');
  let fromSearch = par.get('search');
  let isLink = par.has('link') ? true : false;
  let isBusinessModelId = par.get('businessModelId');
  let isCategoryId = par.get('categoryId');
  let isPortfolioBrand = par.get('portfolioBrand');
  const [showLoader, setShowLoader] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const pathName = window.location.pathname;
  const selectedPortfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);

  interface Category {
    name: any;
  }

  interface Lesson {
    id: number;
    title: string;
    description: string;
    content: string;
    heading: string;
    name: string;
    preamble: string;
    url: string;
  }

  interface PageData {
    articles: any;
    catDescription: string;
    catHeading: string;
    categoryHeroHeading: string;
    categoryHeroDescription: string;
    heading: string;
    preamble: string;
    mainBody: string;
    categoryHeroImageUrl: string;
    tableOfContents: TocItem[];
    showTableOfContents: boolean;
    nextArticleUrl: string;
    nextArticleTitle: string;
    hasNextArticle: boolean;
    properties: any;
  }

  interface TocItem {
    text: string;
    blockId: number;
  }

  interface LocationState {
    from: {
      pathname: string;
      state: any;
    };
    cat: any;
    category: any;
  }

  interface Params {
    portfolioBrand: any;
    category: any;
    categoryname: any;
    articlename: any;
  }

  const [lessonList, setLessonList] = useState<any[]>([]);
  const [currentLesson, setCurrentLesson] = useState<Lesson>();
  const [currentIndex, setCurrentIndex] = useState();
  const [pageData, setPageData] = useState<any>();
  const [categoryData, setCategoryData] = useState<Category>();
  const location = useLocation<LocationState>();
  const params = useParams<Params>();
  const [activeId, setActiveId] = useState(0);
  const [notificationArticles, setNotificationArticles] = useState<any>([]);
  const [showNotificationArticles, setShowNotificationArticles] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');

  let contentUrl = location.state?.cat.url;
  if (params?.category) {
    contentUrl =
      '/' +
      params?.portfolioBrand +
      '/' +
      params?.category +
      '/' +
      params?.categoryname +
      '/' +
      params?.articlename +
      '/';
  }

  //get categoryid and businessmodelid from session
  let entityValues = window.sessionStorage.getItem('entitySelectedOption')?.split(':');
  let categoryid = isCategoryId ? isCategoryId : entityValues?.[0];
  let businessmodelId = isBusinessModelId ? isBusinessModelId : entityValues?.[1];
  let pageType = params?.category === 'learn' ? 'LearnPage' : 'SustainabilityPage';
  let portfolioBrand = isPortfolioBrand;
  const userType = window.sessionStorage?.getItem('userType');

  useEffect(() => {
    if (loadingCount > 0) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loadingCount]);

  const incrementLoader = () => setLoadingCount((prev) => prev + 1);
  const decrementLoader = () => setLoadingCount((prev) => Math.max(prev - 1, 0));

  const getCategoryList = () => {
    if (categoryid && businessmodelId) {
      incrementLoader();
      getArticles(categoryid, businessmodelId, pageType, portfolioBrand)
        .then((response: any) => {
          if (response) {
            if (pageType === 'LearnPage' && window.sessionStorage.getItem('pageData') === 'show') {
              window.sessionStorage.setItem('allArticles', JSON.stringify(response?.results));
              dispatch(updateAllArticles(response?.results ? response?.results : null));
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          decrementLoader();
        });
    }
  };

  const getLessonList = () => {
    incrementLoader();
    getContent(contentUrl, categoryid, businessmodelId, pageType, portfolioBrand)
      .then(function (data) {
        if (data) {
          let indexOn = 0;
          let filtered: any = data.articles ? data.articles : [];

          filtered = filtered.filter((value, index, self) => {
            return self.findIndex((v) => v.url === value.url) === index;
          });

          filtered.map((filteredItem: any, filteredIndex: number) => {
            if (filteredItem.url === contentUrl) {
              indexOn = filteredIndex;
              setActiveId(filteredIndex);
            }
          });

          setLessonList(filtered);

          if (filtered.length > 0) {
            // setCurrentLesson(filtered.length === 1 ? filtered[0] : filtered[indexOn]);
            setPageData(data);
            window.sessionStorage.setItem('pageData', 'show');
          } else {
            window.sessionStorage.setItem('pageData', 'hide');
          }
        } else {
          setLessonList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        decrementLoader();
      });
  };

  const getBlocklist = () => {
    if (currentLesson) {
      incrementLoader();
      getContent(currentLesson?.url, categoryid, businessmodelId, pageType, portfolioBrand)
        .then(function (data) {
          if (data) {
            setPageData(data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          decrementLoader();
        });
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem(contentUrl) === null) {
      sessionStorage.setItem(contentUrl, JSON.stringify(location.state?.cat));
    }
    setCategoryData(sessionStorage.getItem(contentUrl) ? sessionStorage.getItem(contentUrl) : location.state?.cat);
    if (!location.state?.cat) {
      getLessonList();
    }
    getNotificationList(categoryid, businessmodelId);
    getCategoryList();
  }, []);

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = pageData?.mainBody;
    const anchorTags = tempElement.querySelectorAll('a');

    let modifiedContent = pageData?.mainBody;

    anchorTags.forEach((anchor) => {
      if (anchor.origin === window.location.origin) {
        if (anchor.pathname.includes('/learn/') || anchor.pathname.includes('/sustainability/')) {
          const newUrl = `/article${anchor.pathname}`;
          modifiedContent = modifiedContent.replace(anchor.pathname, newUrl);
        }
      }
    });

    setHtmlContent(modifiedContent);
  }, [pageData?.mainBody]);

  useEffect(() => {
    getBlocklist();
    let url =
      isOnboarding === 'onboarding'
        ? '/article' + currentLesson?.url + search
        : fromSearch
        ? '/article' + currentLesson?.url + search
        : '/article' + currentLesson?.url + search;
    if (!search) {
      url =
        url +
        '?categoryId=' +
        categoryid +
        '&businessModelId=' +
        businessmodelId +
        '&pageType=' +
        pageType +
        '&portfolioBrand=' +
        portfolioBrand;
    }
    currentLesson?.url && props.history.push(url);
  }, [currentLesson]);

  useEffect(() => {
    let url = pathName + search;

    if (!search) {
      url =
        url +
        '?categoryId=' +
        categoryid +
        '&businessModelId=' +
        businessmodelId +
        '&pageType=' +
        pageType +
        '&portfolioBrand=' +
        portfolioBrand;
    }

    if (userType === 'Partner') {
      const entitySelectedOptionValue = categoryid + ':' + businessmodelId;
      const partnerDetail = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);
      const filteredByBrand = partnerDetail?.partnerEntity?.filter((entity) => entity.brandId === portfolioBrand);
      const isMatchFound = filteredByBrand?.some(
        (entity) => entity.categoryId === categoryid && entity.businessModelId === businessmodelId,
      );
      if (isMatchFound && selectedPortfolioBrand?.value === portfolioBrand) {
        window.sessionStorage.setItem('entitySelectedOption', entitySelectedOptionValue);
        pathName && props.history.push(url);
      } else {
        window.sessionStorage.setItem('pageData', 'hide');
        window.sessionStorage.setItem('allArticles', JSON.stringify([]));
        dispatch(updateAllArticles({ allArticles: [] }));
        props.history.push('/learn');
      }
    } else {
      pathName && props.history.push(url);
    }
  }, [pathName]);

  useEffect(() => {
    if (window.sessionStorage.getItem('pageData') === 'hide') {
      window.sessionStorage.setItem('allArticles', JSON.stringify([]));
      dispatch(updateAllArticles({ allArticles: [] }));
      props.history.push('/learn');
    }
  }, [window.sessionStorage.getItem('pageData')]);

  useEffect(() => {
    if (location.state?.cat) {
      getLessonList();
    }
  }, [location.state?.cat]);

  const getNotificationList = (categoryIdValue, businessModelIdValue) => {
    if (categoryIdValue && businessModelIdValue) {
      incrementLoader();
      getNotification(categoryIdValue, businessModelIdValue, pageType, portfolioBrand)
        .then((response: any) => {
          if (response) {
            setNotificationArticles(response?.results);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          decrementLoader();
        });
    }
  };

  const onNotification = () => {
    setShowNotificationArticles(true);
  };

  const closePopup = () => {
    setShowNotificationArticles(false);
  };

  return (
    <div className="u-overflowX-hidden learnPage articlePage">
      {pageData && (
        <>
          <ArticleHero
            preamble={pageData?.catDescription}
            heading={pageData?.catHeading}
            hasLink={isLink}
            pageType={pageType}
          ></ArticleHero>
          <Container fluid className="printHidden">
            <Row>
              <Col>
                <Tabs
                  lessonList={lessonList}
                  setCurrentLesson={setCurrentLesson}
                  setCurrentIndex={setCurrentIndex}
                  setActiveId={setActiveId}
                  activeId={activeId}
                ></Tabs>
              </Col>
            </Row>
          </Container>
          <Container>
            <article className="Article">
              <div className="Article-content Article-content--size1of1">
                <Row className="justify-content-md-center">
                  <Col lg={12}>
                    <div>
                      {pageData?.properties?.Heading && (
                        <h2 className="Heading articleHeading">{pageData?.properties?.Heading}</h2>
                      )}
                      {pageData?.properties?.Preamble && <p className="Preamble">{pageData?.properties?.Preamble}</p>}
                    </div>
                    {htmlContent && (
                      <p className="ArticleText">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: htmlContent!,
                          }}
                        />
                      </p>
                    )}
                  </Col>
                </Row>
              </div>
            </article>
            {pageData && pageData?.properties?.ShowTableOfContents ? (
              <Toc items={pageData?.tableOfContents}></Toc>
            ) : (
              <></>
            )}
            <article className="Article">
              {pageData &&
                pageData?.properties?.MainContentFriendly?.map((blockListItem: any, index: number) => {
                  return <Block position={index} block={blockListItem}></Block>;
                })}
            </article>
          </Container>
          <Container className="printHidden">
            {lessonList &&
              lessonList?.map((lessonListItem: any, index: number) => {
                const hasNextArticle = index !== lessonList?.length - 1;
                const nextArticleUrl = lessonList?.[index + 1]?.url + search;
                const nextArticleTitle = lessonList?.[index + 1]?.heading;

                if (isLink) {
                  if (lessonListItem?.url === contentUrl && hasNextArticle) {
                    return (
                      <Nextarticle
                        hasNextArticle={hasNextArticle}
                        nextArticleUrl={
                          isOnboarding
                            ? hasNextArticle
                              ? '/article' + nextArticleUrl
                              : '/onboarding'
                            : hasNextArticle
                            ? '/article' + nextArticleUrl
                            : pageType === 'LearnPage'
                            ? '/learn?entity=selected'
                            : '/sustainability?entity=selected'
                        }
                        pageType={pageType}
                        nextArticleTitle={nextArticleTitle}
                      />
                    );
                  }
                } else {
                  if (lessonListItem?.url === contentUrl) {
                    return (
                      <Nextarticle
                        hasNextArticle={hasNextArticle}
                        nextArticleUrl={
                          isOnboarding
                            ? hasNextArticle
                              ? '/article' + nextArticleUrl
                              : '/onboarding'
                            : hasNextArticle
                            ? '/article' + nextArticleUrl
                            : pageType === 'LearnPage'
                            ? '/learn?entity=selected'
                            : '/sustainability?entity=selected'
                        }
                        pageType={pageType}
                        nextArticleTitle={nextArticleTitle}
                      />
                    );
                  }
                }
              })}
          </Container>
        </>
      )}
      {notificationArticles.length > 0 ? (
        <button id="notificationBtn" onClick={onNotification} title="Notification">
          <img src={Notification} alt="Notification Icon" />
        </button>
      ) : null}
      {showNotificationArticles ? (
        <NotificationArticles
          title="Updated Content"
          categoryId={categoryid}
          businessModelId={businessmodelId}
          portfolioBrand={portfolioBrand}
          pageType={pageType}
          notificationArticles={notificationArticles}
          setShowLoader={setShowLoader}
          closePopup={closePopup}
        />
      ) : null}
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Article;
