import { useState, useEffect } from 'react';
import './index.scss';
import Hero from '../../components/Hero';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import HeroImage from '../../assets/images/learnImage.jpg';
import { search, getNotification } from '../../services/CmsService';
import Loader from '../../components/Shared/Loader';
import EntitySelector from '../../components/EntitySelector';
import CategoryListing from '../../components/CategoryListing';
import { getEntityListForLearn } from '../../helpers/utilities';
import { useDispatch } from 'react-redux';
import { updateAllArticles } from '../../store/actions/learnAction';
import Notification from '../../assets/images/icons/Notification.svg';
import NotificationArticles from '../../components/NotificationArticles';
import heroFashion from '../../assets/images/fashion.png';
import heroBeauty from '../../assets/images/beauty.png';
import heroHome from '../../assets/images/homeNew.png';
import sustainabilityImage from '../../assets/images/sustainabilityImage.png';

const Learn = () => {
  const dispatch = useDispatch();
  let entity = window.location.search;
  let par = new URLSearchParams(entity);
  let isEntity = par.get('entity');
  const pathname = window.location.pathname;
  let pageType = pathname?.replace(/^\/|\/$/g, '') === 'learn' ? 'LearnPage' : 'SustainabilityPage';

  const [searchWord, setSearchWord] = useState('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [businessModelId, setBusinessModelId] = useState();
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const [entityList, setEntityList] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [notificationArticles, setNotificationArticles] = useState<any>([]);
  const [showNotificationArticles, setShowNotificationArticles] = useState(false);
  const [heroImage, setHeroImage] = useState(HeroImage);
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!)?.value;

  const onInputchange = (event: any) => {
    if (event.target.value) {
      setSearchValue(event.target.value);
    } else {
      setSearchValue(event.target.value);
      setSearchWord('');
      setSearchResult([]);
    }
  };

  const onInputClear = () => {
    setSearchValue('');
    setSearchWord('');
    setSearchResult([]);
  };

  const onInputKeyUp = (event) => {
    if (event.target.value.length <= 3) {
      setSearchWord('');
      setSearchResult([]);
    } else {
      if (event.key === 'Enter' && event.target.value.length > 3) {
        setShowLoader(true);
        setSearchWord(event.target.value);
        search(
          '/partnerportal/search?q=' +
            event.target.value.trim() +
            '&portfolioBrand=' +
            portfolioBrand +
            '&businessModelId=' +
            businessModelId +
            '&categoryId=' +
            categoryId +
            '&pageType=' +
            pageType,
        ).then((result) => {
          setSearchResult(result?.results);
          setShowLoader(false);
        });
      }
    }
  };

  const handleEntity = (selectedOption: any) => {
    let entityValues = selectedOption?.value?.split(':');
    window.sessionStorage.setItem('entitySelectedOption', selectedOption?.value);
    setCategoryId(entityValues?.[0]);
    setBusinessModelId(entityValues?.[1]);
  };

  useEffect(() => {
    let entityValues: any = selectedEntity ? selectedEntity?.value?.split(':') : '';
    if (entityValues) {
      setCategoryId(entityValues[0]);
      setBusinessModelId(entityValues[1]);
    }
    if (pageType === 'LearnPage') {
      if (entityValues?.[0] === '10001') {
        setHeroImage(heroFashion);
      } else if (entityValues?.[0] === '10002') {
        setHeroImage(heroHome);
      } else if (entityValues?.[0] === '10003') {
        setHeroImage(heroBeauty);
      } else if (entityValues?.[0] === '10004') {
        setHeroImage(HeroImage);
      } else {
        setHeroImage(HeroImage);
      }
    } else {
      setHeroImage(sustainabilityImage);
    }
  }, [selectedEntity]);

  useEffect(() => {
    const entityList: any = getEntityListForLearn();
    setEntityList(entityList);
    if (entityList?.length === 1) {
      handleEntity(entityList?.[0]);
      setSelectedEntity(entityList?.[0]);
    } else {
      const storedEntityValue = window.sessionStorage.getItem('entitySelectedOption');
      const preselectedEntity = entityList.find((entity) => entity.value === storedEntityValue);
      if (isEntity) {
        handleEntity(preselectedEntity);
      }
    }
    // if (isEntity) {
    //   let entitySelectedOption: any = window.sessionStorage.getItem('entitySelectedOption');
    //   handleEntity(
    //     entityList?.filter((entity) => {
    //       return entity.value === entitySelectedOption;
    //     })?.[0],
    //   );
    //   setSelectedEntity(
    //     entityList?.filter((entity) => {
    //       return entity.value === entitySelectedOption;
    //     })?.[0],
    //   );
    // }

    if (!isEntity || entityList?.length > 1) {
      dispatch(updateAllArticles({ allArticles: [] }));
      window.sessionStorage.setItem('allArticles', JSON.stringify([]));
    }
    window.sessionStorage.setItem('pageType', pageType);
  }, []);

  useEffect(() => {
    if (selectedEntity?.value) {
      const [categoryId, businessModelId] = selectedEntity.value.split(':');
      if (categoryId && businessModelId) {
        setShowLoader(true);
        getNotification(categoryId, businessModelId, pageType, portfolioBrand)
          .then((response) => {
            setNotificationArticles(response?.results || []);
            setShowLoader(false);
          })
          .catch((error) => {
            console.error('Error fetching notifications:', error);
            setShowLoader(false);
          });
      }
    }
  }, [selectedEntity]);

  const onNotification = () => {
    setShowNotificationArticles(true);
  };

  const closePopup = () => {
    setShowNotificationArticles(false);
  };

  return (
    <div className="u-overflowX-hidden learnPage">
      {heroImage && (
        <Hero className="Hero Hero-gradient " heroImage={heroImage}>
          {pageType === 'LearnPage' ? (
            <>
              <h1 className="Heading Hero-heading">Learn</h1>
              <p className="Hero-preamable">
                Find information on topics related to your partnership with H&M Group. <br /> Navigate through the
                categories or use the search field.
              </p>
            </>
          ) : (
            <>
              <h1 className="Heading Hero-heading">Sustainability</h1>
              <p className="Hero-preamable">
                Learn about sustainability at H&M Group and what it means for your partnership with us.
              </p>
            </>
          )}
        </Hero>
      )}
      <Container>
        <article className="Learn">
          <div className="Learn-content Learn-content--size1of1">
            <Row>
              <Col style={{ marginBottom: 40, marginTop: 40 }}>
                {/* <h2 className="Heading Heading--h5">What information are you looking for?</h2> */}
                {/* <p className="Preamble">Navigate through the categories or use the search field.</p> */}
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                {entityList?.length > 1 && (
                  <div className="EntitySwitch">
                    <EntitySelector
                      placeHolder={'View as...'}
                      handleEntity={handleEntity}
                      setSelectedEntity={setSelectedEntity}
                      isEntity={isEntity}
                    ></EntitySelector>
                    <br></br>
                  </div>
                )}

                {selectedEntity && Object.keys(selectedEntity)?.length > 1 ? (
                  <div className="right-inner-addon input-container">
                    {searchValue && <i className="icon-Delete-circle" onClick={onInputClear}></i>}
                    <input
                      type="text"
                      value={searchValue}
                      className={`form-control`}
                      placeholder="Search for"
                      onChange={onInputchange}
                      onKeyUp={onInputKeyUp}
                    />
                    <br></br>
                  </div>
                ) : null}
              </Col>
            </Row>
            {searchWord.trim().length > 3 && searchResult.length === 0 && <>No results found</>}
            {searchResult?.length > 0 && (
              <>
                <Row className="gx-4 gy-4">
                  <Col xs={12} sm={12} md={12} lg={2}>
                    {searchResult.length} results
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={2}></Col>
                </Row>
                <Row className="gx-4 gy-4">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {searchResult?.map((res: any) => {
                      return (
                        <a
                          href={`/article${res.url}?search=${searchWord}&portfolioBrand=${portfolioBrand}&categoryId=${categoryId}&businessModelId=${businessModelId}&pageType=${pageType}`}
                          key={res?.id}
                        >
                          <div className="searchList">
                            <h6>{res?.catHeading}</h6>
                            <p>{res?.heading}</p>
                          </div>
                        </a>
                      );
                    })}
                  </Col>
                </Row>
              </>
            )}

            <Row className="gx-4 gy-4">
              {searchWord.trim().length === 0 && (
                <CategoryListing
                  selectedEntity={selectedEntity}
                  onBoarding={false}
                  categoryId={categoryId}
                  businessModelId={businessModelId}
                  portfolioBrand={portfolioBrand}
                  pageType={pageType}
                  setShowLoader={setShowLoader}
                ></CategoryListing>
              )}
            </Row>

            <br />
          </div>
        </article>
      </Container>
      {notificationArticles.length > 0 ? (
        <button id="notificationBtn" onClick={onNotification} title="Notification">
          <img src={Notification} alt="Notification Icon" />
        </button>
      ) : null}
      {showNotificationArticles ? (
        <NotificationArticles
          title="Updated Content"
          categoryId={categoryId}
          portfolioBrand={portfolioBrand}
          businessModelId={businessModelId}
          notificationArticles={notificationArticles}
          pageType={pageType}
          setShowLoader={setShowLoader}
          closePopup={closePopup}
        />
      ) : null}
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Learn;
